<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    entity-name="龛位"
    hide-show-detail
    hide-delete
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :action="action"
    :requestConfig="{ noTempleFilter: true }"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :default-query="defaultQuery"
  >
    <template v-slot:button>
      <a-button type="primary" @click="goOverview">龛位总览</a-button>
    </template>
    <template v-slot:query="{ form }">
      <a-form-model-item label="所在区域">
        <a-select
          style="width: 180px"
          placeholder="选择区域筛选"
          allow-clear
          @change="updateList"
          :options="areaList"
          v-model="form.district_id"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="龛位编号">
        <a-input-search
          allow-clear
          v-model="form.num"
          placeholder="输入龛位编号搜索"
          style="width: 180px"
          @search="updateList"
        />
      </a-form-model-item>
      <a-form-model-item label="供奉状态">
        <a-select
          style="width: 180px"
          placeholder="选择供奉状态筛选"
          allow-clear
          @change="updateList"
          :options="worshipStatusList"
          v-model="form.worship_status"
        >
        </a-select>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="所在区域">{{ form.district && form.district.name }}</a-form-model-item>
<!--      <a-form-model-item label="龛位编号">{{ form.num }}</a-form-model-item>-->
      <a-form-model-item label="龛位编号" prop="name">
        <a-input v-model="form.num" placeholder="输入龛位编号（最多5个字符）" :maxLength="5" allow-clear></a-input>
      </a-form-model-item>
      <a-form-model-item label="龛位价格" prop="price">
        <a-input-number :min="0.01" :precision="2" v-model="form.priceNum" placeholder="请输入龛位价格" style="width: 100%"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="是否对外开放" prop="status">
        <a-switch v-model="form.status"></a-switch>
      </a-form-model-item>
    </template>
    <template v-slot:action="{ item, index }">
      <a class="txt-btn" @click.stop="goDetail(item, index)">查看订单</a>
    </template>
  </data-list>
</template>

<script>
import { dealAreaList } from "./light";
import { worshipStatusList } from "./light";

export default {
  name: "NicheNewList",
  data() {
    return {
      url: "/admin/mini-worship-buddhist-niche/worship-list",
      worshipStatusList,
      searchKeyType: { num: 2, district_id: 2, worship_status:2 },
      columns: [
        {
          title: "所在区域",
          dataIndex: "district",
          customRender: (text) => {
            return <span>{text?.name}</span>;
          },
        },
        { title: "龛位编号", dataIndex: "num" },
        // { title: "龛位名称", dataIndex: "name" },
        { title: "龛位价格", dataIndex: "price", customRender: text => `¥${text / 100}`},
        {
          title: "供奉状态",
          dataIndex: "worshipStatus",
          customRender: (text) => {
            return <span>{text ? "已供奉" : "未供奉"}</span>;
          },
        },
        {
          title: "是否开放",
          customRender: (record) => {
            return (
              <a-switch checked={record.status == 1} onChange={($event) => this.onChange($event, record)}></a-switch>
            );
          },
        },
      ],
      rules: {
        name: [{ required: false, message: "请输入龛位名称", trigger: "blur" }],
        status: [{ required: true, message: "请选择是否开放", trigger: "change", type: "boolean" }],
      },
      areaList: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    defaultQuery() {
      const status = this.$route.query.status;
      if (status) {
        return { worship_status: status * 1 };
      } else {
        return undefined;
      }
    }
  },
  created() {
    this.getArea();
  },
  methods: {
    beforeEdit(form, fn) {
      form.status = form.status == 1;
      form.priceNum = form.price / 100;
      fn(form);
    },
    beforeUpdate(form, fn) {
      form.status = form.status ? 1 : 2;
      form.price = Math.round(form.priceNum * 100);
      fn(form);
    },
    getArea() {
      return this.$axios({
        url: "/admin/mini-worship-district",
        method: "GET",
        noTempleFilter: true,
      }).then((res) => {
        let areaList = res.data;
        dealAreaList(areaList);
        this.areaList = areaList;
      });
    },
    updateList() {
      this.$refs.dataList.updateList();
    },
    onChange(checked, record) {
      let text = checked ? "开放" : "关闭";
      this.$confirm({
        title: "提示",
        content: `确定${text}此龛位吗？`,
        onOk: () => {
          this.$axios({
            url: `/admin/mini-worship-buddhist-niche/${record.id}`,
            method: "PATCH",
            data: {
              status: checked ? 1 : 2,
            },
          }).then((res) => {
            record.status = res.status;
            this.$message.success(text + "成功");
          });
        },
      });
    },
    goOverview() {
      this.$router.push({ name: "NicheOverview" });
    },
    goDetail(item) {
      this.$router.push({ name: "NicheDetail", params: { id: item.id } });
    },
  },
};
</script>

<style lang="less" scoped></style>
