import { getKeyTitle, paddingZero } from "../../common/js/tool";
import req from "../../common/js/req";

const pro = process.env.NODE_ENV === 'production';

export const lightOrderCatList = [
  { key: 1, title: "小程序" },
  { key: 2, title: "一体机" },
  { key: 3, title: "管理后台" },
];

export const areaList = [
  { key: 1, title: "东区", row: 14, col: 46 },
  { key: 2, title: "北区", row: 14, col: 100 },
  { key: 3, title: "西区", row: 14, col: 46 },
];

export const dateList = [
  { key: 1, title: "1天", days: 1 },
  { key: 2, title: "7天", days: 7 },
  { key: 3, title: "30天", days: 30 },
  { key: 4, title: "6个月", days: 180 },
  { key: 5, title: "1年", days: 365 },
  { key: 6, title: "自定义" },
];

export const wishTypeList = [
  {
    value: 1,
    label: "祈福学业",
    children: [
      "定慧等持，学业进步。",
      "智慧无碍，学有所成。",
      "顺缘增上，融会贯通。",
      "增智开慧，妙笔生辉。",
      "勇猛精进，金榜题名。",
    ].map(item => Object({value: item, label: item})),
  },
  {
    value: 2,
    label: "祈福健康",
    children: [
      "身体健康，平安喜乐。",
      "拨除重病，安住清净。",
      "消除业障，如意安康。",
      "身心安泰，清净无染。",
      "消灾免难，益寿延年。",
    ].map(item => Object({value: item, label: item})),
  },
  {
    value: 3,
    label: "祈福财富",
    children: [
      "众善奉行，财运亨通。",
      "慈悲利他，财源广进。",
      "广行六度，安乐富足。",
      "广结善缘，生意兴隆。",
      "法喜充满，富贵吉祥。",
    ].map(item => Object({value: item, label: item})),
  },
  {
    value: 4,
    label: "祈福事业",
    children: [
      "心想事成，妙境随心。",
      "一帆风顺，前程似锦。",
      "步步高升，蒸蒸日上。",
      "梦想成真，安居乐业。",
      "与人为善，兴旺发达。",
    ].map(item => Object({value: item, label: item})),
  },
  {
    value: 5,
    label: "祈福姻缘",
    children: [
      "花好圆月，佳偶天成。",
      "永结同心，幸福美满。",
      "天缘巧合，美满良缘。",
      "花开并蒂，琴瑟和鸣。",
      "相亲相爱，郎才女貌。",
    ].map(item => Object({value: item, label: item})),
  },
];

export const nicheOrderStatusList = [
  { key: 0, title: "待支付" },
  { key: 1, title: "已支付" },
  { key: 2, title: "已超时" },
  { key: 3, title: "已取消" },
  { key: 4, title: "已退款" },
];

export const expireList = [
  { key: 1, title: "1天" },
  { key: 2, title: "2天" },
  { key: 3, title: "3天" },
  { key: 7, title: "7天" },
  { key: 15, title: "15天" },
  { key: 30, title: "30天" },
];

export const nameTypeList = [
  { key: 1, title: "个人" },
  { key: 2, title: "阖家" },
  { key: 3, title: "公司" },
];

export const worshipStatusList = [
  { key: 1, title: "已供奉" },
  { key: 2, title: "未供奉" },
];

export const authStatusList = [
  { key: 1, title: "开启" },
  { key: 2, title: "禁用" },
];

export const getLightOrderCatText = function(cat) {
  return getKeyTitle(lightOrderCatList, cat);
};

export const getNicheOrderStatusText = function(status) {
  return getKeyTitle(nicheOrderStatusList, status);
};

export const getLightAreaText = function(area) {
  return getKeyTitle(areaList, area);
};

export const getWishTypeText = function(type) {
  return getKeyTitle(wishTypeList, type, "value", "label");
};

export const getShowPrice = function(price) {
  price = price || 0;
  return `¥ ${(price / 100).toFixed(2)}`;
};

export const getIntegerAndDecimal = function(price) {
  return {
    integer: Math.floor(price / 100), // 价格整数位
    decimal: paddingZero(price % 100, 2), // 价格小数位
  };
};

export const dealNicheOrder = function(item) {
  item.statusText = getNicheOrderStatusText(item.status);
  const start = new Date(item.start_time * 1000);
  const end = new Date(item.end_time * 1000);
  item.provideTime = `${start.pattern("yyyy年M月d日")}-${end.pattern("yyyy年M月d日")}`;
  item.typeText = getWishTypeText(item.type);
  item.orderPrice = getShowPrice(item.price);
  let nichePrice = item.price;
  if (item.order_info) {
    try {
      const detail = JSON.parse(item.order_info);
      item.goods = detail.goods;
      item.showArea = detail.showArea;
      if(detail.nichePrice) {
        nichePrice = detail.nichePrice;
      }
      if(item.cat == 3) {
        item.bill = detail.bill;
        item.processor = detail.processor;
      }
    } catch (e) {
      console.error(e);
    }
  }
  item.lightPrice = getShowPrice(nichePrice);
  item.goodsPrice = getShowPrice(item.price - nichePrice);
  if (!item.showArea && item.detail) {
    item.showArea = `${getLightAreaText(item.detail.district)} ${item.detail.num}`;
  }
  item.showGoods =
    item.goods && item.goods.length > 0 ? item.goods.map((g) => `${g.name}x${g.count}`).join("、") : "无";
};

export const dealNiche = function (item) {
  item.disabled = item.status == 2 || !!item.worshipStatus;
  item.district = item.district_id;
  item.statusText = item.worshipStatus ? "已供奉" : (item.status == 1 ? "未供奉" : "未开启");
}

export const dealNicheList = function(data) {
  let list = [];
  data?.forEach((item) => {
    dealNiche(item);
    let index = item.row - 1;
    if (list[index]) {
      list[index].push(item);
    } else {
      list[index] = [item];
    }
  });
  list.sort((a, b) => b[0]?.row - a[0]?.row);
  list.forEach((item) => {
    item.sort((a, b) => a.cols - b.cols);
  });
  return list;
};

export const dealAreaList = function(data) {
  data.forEach((district) => {
    district.key = district.id;
    district.title = district.name;
    district.list = dealNicheList(district.buddhistNiche);
  });
};

export const dispatchNicheLight = (niche, type) => {
  const url = "/screen/mini-worship-order/light-web";
  return pro ? req.post(url, {
    type,
    row: niche.row,
    cols: niche.cols,
    district: niche.district_id,
    buddhist_niche_id: niche.id,
  }): Promise.resolve({ error: 0, msg: "success" });
};

